<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title d-flex justify-content-center">
        <div class="icon-rounded icon-rounded-primary">
          <slot name="icon"/>
        </div>
      </h4>
      <h1 class="text-center">{{ value }}</h1>
      <p class="text-center">{{ title }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CounterCardComponent',
  props: {
    title: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
  },
};
</script>

<style scoped>
.card-title {
  .icon-rounded-primary {
    background-color: var(--clr-yup-purple);
    color: white;

    .material-symbols-outlined {
      font-size: 2.5rem;
      padding-top: 4px;
    }
  }
}
</style>
